import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Typography } from "@mui/material"

import Link from "@mui/material/Link"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"

const Articles = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo
        title="Articles"
        description="Must read articles which will help you for making your loan repayment related decisions"
      />
      {posts.map(post => {
        const title = post.frontmatter.title || post.fields.slug

        return (
          <Card key={post.fields.slug}>
            <CardContent>
              <Typography variant="h4" component="h2">
                <Link href={post.fields.slug} underline="hover">
                  {title}
                </Link>
              </Typography>
              <Typography variant="body2">
                Last updated: {post.frontmatter.date}
              </Typography>

              <Typography
                sx={{ mt: 2 }}
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.description || post.excerpt,
                }}
                itemProp="description"
              />
            </CardContent>
          </Card>
        )
      })}
    </Layout>
  )
}

export default Articles

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
